module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SemperHilaris","short_name":"SemperHilaris","description":" ","start_url":"/","background_color":"#343334","theme_color":"#343334","display":"standalone","icon":"src/assets/images/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c79b90d9c216220f3fd5eb838230b540"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_x5yfatimjsesctsfzypxksxhp4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
